export enum FlexDirection {
  ROW = "ROW",
  COLUMN = "COLUMN",
}

export enum Align {
  START = "START",
  END = "END",
  CENTER = "CENTER",
  STRETCH = "STRETCH",
}

export enum Justify {
  START = "START",
  END = "END",
  CENTER = "CENTER",
  STRETCH = "STRETCH",
  SPACE_BETWEEN = "SPACE_BETWEEN",
  SPACE_EVENLY = "SPACE_EVENLY",
  SPACE_AROUND = "SPACE_AROUND",
}

export enum Gap {
  NONE = "NONE",
  XS = "XS",
  SM = "SM",
  MD = "MD",
  LG = "LG",
  XL = "XL",
}
